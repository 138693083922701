import WorkOrderPriorityLabel from '@/components/workOrders/WorkOrderPriorityLabel';
import { formatDateToMDHHmm_or_YYYYMDHHmm } from '@/utils/date/date';
import { REQUESTS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { FC } from 'react';
import { RequestAssigneesLabel } from './RequestAssigneesLabel';
import { RequestCard_RequestFragment } from './RequestCard.generated';
import RequestStatusLabel from './RequestStatusLabel';

gql`

fragment RequestCard_Request on Request {
  id
  title
  status
  priority
  workOrders {
    status
  }
  createdAt
  createdBy {
    id
    name
  }
  assignees {
    id
    name
  }
  groups {
    id
    name
  }
}
`;

export type RequestCardProps = {
  isActive?: boolean;
  request: RequestCard_RequestFragment;
  href?: string;
};

const RequestCard: FC<RequestCardProps> = (props: RequestCardProps) => {
  const {
    isActive,
    href,
    request: {
      id,
      title,
      status,
      priority,
      createdAt,
      createdBy,
      workOrders,
      // assignees, groupsはDisplayRequestで取得できないので、初期値を設定している。
      assignees = [],
      groups = [],
    },
  } = props;
  const workOrderStatus = workOrders?.at(0)?.status;
  const { t_ns } = useTranslation(REQUESTS);

  return (
    <Link to={href ? href : `/requests/${id}`}>
      <Flex
        flexDirection='column'
        rowGap='1'
        px='3'
        py={2}
        _hover={{
          bg: isActive ? 'primary.50' : 'neutral.50',
          cursor: 'pointer',
        }}
        bg={isActive ? 'primary.50' : 'transparent'}
        borderWidth={1}
      >
        <Text
          fontWeight='semibold'
          fontSize='lg'
          letterSpacing='wide'
          color='neutral.800'
          lineHeight='6'
        >
          {title}
          <Text as='span' pl='1' fontWeight='normal'>
            <RequestStatusLabel status={status} workOrderStatus={workOrderStatus} />
          </Text>
        </Text>
        <RequestAssigneesLabel
          assignees={assignees}
          groups={groups}
          requestStatus={status}
          workOrderStatus={workOrderStatus}
        />
        <Flex my={1} justifyContent='space-between' alignItems='center' columnGap='0.5'>
          <Text color='neutral.500' fontSize='xs' lineHeight='none' ml={1}>
            {formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}
            <Text as='span' pl='1'>
              {createdBy?.name && t_ns('request-assigner-label', { assigner: createdBy?.name })}
            </Text>
          </Text>
          {priority && <WorkOrderPriorityLabel priority={priority} />}
        </Flex>
      </Flex>
    </Link>
  );
};

export default RequestCard;
